@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;500;700&display=swap');

@layer components {

  .btn-primary {
    @apply text-white rounded bg-blue-500 text-sm font-medium px-4 py-1.5 font-sans;
  }

  .btn-secondary {
    @apply text-slate-700 rounded bg-slate-200 text-sm font-medium px-4 py-1.5 font-sans;
  }

  .input-text {
    @apply border rounded border-gray-200 p-1.5 text-base w-full block;
  }

  .input-label {
    @apply font-light text-base text-slate-500;
  }

  .loading {
    @apply animate-pulse;
  }
}
